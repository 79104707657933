/* Article - https://bitsofco.de/github-contribution-graph-css-grid/ */

/* Grid-related CSS */

:root {
    --square-size: 10px;
    --square-gap: 5px;
    --week-width: calc(var(--square-size) + var(--square-gap));
}

.months {
    grid-area: months;
}

.months > li, .days li {
    font-size: 12px;
}

.days {
    grid-area: days;
    margin: 0 !important;
    padding: 0 !important;
}

li::marker {
    content: "";
}

.squares {
    grid-area: squares;
    margin: 0 !important;
    padding: 0 !important;
}

.graph {
    display: inline-grid;
    grid-template-areas: "empty months"
        "days squares";
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
}

.months {
    display: grid;
    padding: 0 !important;
    margin: 0 !important;
    grid-template-columns: calc(var(--week-width) * 4)
        /* Jan */
        calc(var(--week-width) * 4)
        /* Feb */
        calc(var(--week-width) * 4)
        /* Mar */
        calc(var(--week-width) * 5)
        /* Apr */
        calc(var(--week-width) * 4)
        /* May */
        calc(var(--week-width) * 4)
        /* Jun */
        calc(var(--week-width) * 5)
        /* Jul */
        calc(var(--week-width) * 4)
        /* Aug */
        calc(var(--week-width) * 4)
        /* Sep */
        calc(var(--week-width) * 5)
        /* Oct */
        calc(var(--week-width) * 4)
        /* Nov */
        calc(var(--week-width) * 5)
        /* Dec */
    ;
}

.days,
.squares {
    display: grid;
    grid-gap: var(--square-gap);
    grid-template-rows: repeat(7, var(--square-size));
}

.squares {
    grid-auto-flow: column;
    grid-auto-columns: var(--square-size);
    
}


/* Other styling */

.months .days {
    font-size: 0.2rem;
}
.graph {
    border-radius: 0.5rem;
    padding: 20px;
    border: 1px #e1e4e8 solid;
    padding: 0.8rem;
}

.days li:nth-child(odd) {
    visibility: hidden;
}

.squares li {
    background-color: #ebedf0;
    border-radius: 2px;
}

.dark li {
    background-color: #242424;
}

.squares li[data-level="1"] {
    /* background-color: #c6e48b; */
    background-color: #f7462f;
}

.squares li[data-level="2"] {
    background-color: #7bc96f;
}

.squares li[data-level="3"] {
    background-color: #196127;
}